.carres {
    height:50px;
    background-color: white;
    ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding-right: 4.4%;
    position: fixed;
    width: 100%;
    z-index: 1;
    // padding-left: 10px;
    border-bottom: 0.5px solid #E5E5E5;
    cursor: pointer;

    h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: #FFFFFF;
    }
}

.left-icon , .right-logo {
  margin-left: 24px;
  margin-right: 26px;
  margin-top: 15px;
  &:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }
}

.right-icons {
    display: flex;
    margin-left: auto;
    width: 8.5%;
}

.vl-top {
    border-right: 0.5px solid #E5E5E5;
    height: 100%;
}

.vl-top1 {
    border-left: 0.5px solid #E5E5E5;
    height:50px;
    width:50%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .carres {
        // height: 2rem;
        // background-color: #4CAF50;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
        position: fixed;
        width: 100%;
        z-index: 10;
        padding-left: 5%;

        h5 {
            font-size: 11px;
        }
    }
    .right-icons {
        width: 140px;
    }

}