@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.homePage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .heroSection {
      flex: 0 0 75%;
      max-height: 75%;
      padding: 0 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logoArea {
        flex: 0 0 45%;
        display: flex;
        flex-direction: column;
        .subTitle {
          font-size: 30px;
          font-weight: 600;
        }
        .title {
          font-size: 60px;
          font-weight: bold;
        }
        .desc {
          display: none;
        }
        .btns {
          width: 65%;
          margin-top: 30px;
          flex: 0 0 60px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          .btnReg,
          .btnLearn {
            flex: 0 0 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            border-radius: 12px;
            border: 1px solid $primary-color;
            text-decoration: none;
            @include btn-animate;
          }
          .btnLearn {
            border-color: $app-color;
            background: $app-color;
            color: white;
            @include btn-animate(white);
          }
        }
      }
      .indMap {
        height: 80%;
        width: auto;
      }
    }
    .aboutMock {
      flex: 0 0 80%;
      max-height: 80%;
      padding: 0 80px;
      display: flex;
      align-items: center;
      .mockBg {
        height: 70%;
        width: auto;
      }
      .textAbout {
        margin-left: 15%;
        flex: 1;
        .titleAbout {
          font-size: 70px;
          font-weight: 700;
          width: fit-content;
          margin-bottom: 25px;
          position: relative;
        }
        .aboutDesc {
          width: 75%;
          font-size: 24px;
          line-height: 2;
          margin: 50px 0;
        }
        .btns {
          width: 65%;
          margin-top: 30px;
          flex: 0 0 60px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          .btnReg,
          .btnLearn {
            flex: 0 0 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            border-radius: 12px;
            color: $primary-color;
            text-decoration: none;
            border: 1px solid $border-color;
            @include btn-animate;
          }
          .btnLearn {
            background: $app-color;
            background-color: $app-color;
            color: white;
            @include btn-animate(white);
          }
        }
      }
    }
    .founders {
      padding: 80px;
      display: flex;
      flex-direction: column;
      .titleFounders {
        font-size: 40px;
        font-weight: 700;
        width: fit-content;
        margin-bottom: 25px;
        position: relative;
      }
      .foundersList {
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        .founderCard {
          flex: 0 0 32%;
          height: 300px;
          border: 1px solid $border-color;
          border-radius: 15px;
          display: flex;
          align-items: center;
          text-decoration: none;
          @include btn-animate;
          .founderImg {
            margin-top: auto;
            height: 90%;
            width: 40%;
            object-fit: contain;
          }
          .founderDetail {
            display: flex;
            flex-direction: column;
            .founderName {
              font-size: 40px;
              font-weight: bold;
              color: $primary-color;
            }
            .founderPost {
              font-size: 20px;
              font-weight: 600;
              color: $app-color;
            }
          }
        }
      }
    }
    .whyIndianOtc {
      padding: 80px;
      display: flex;
      flex-direction: column;
      .titleWhy {
        font-size: 40px;
        font-weight: 700;
        width: fit-content;
        margin-bottom: 25px;
        position: relative;
      }
      .reasonList {
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .reasonCard {
          flex: 0 0 23%;
          height: 270px;
          background: white;
          margin: 20px 0;
          border-radius: 15px;
          border: 1px solid $border-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .header {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              height: 40%;
              margin-bottom: 6%;
            }
            span {
              font-size: 20px;
              font-weight: bold;
              text-align: center;
            }
          }
          .desc,
          .bar {
            display: none;
          }
          .header,
          .expanded {
            border-radius: 15px;
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition-duration: 0.5s;
            transition-property: transform, opacity;
          }
          .header {
            transform: rotateY(0deg);
          }
          .expanded {
            position: absolute;
            opacity: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: rotateY(-180deg);
            background: white;
            flex: unset;
            display: flex;
            flex-direction: column;
            padding-left: 40px;
            border-radius: 15px;
            overflow: hidden;
            justify-content: center;
            .bar {
              display: flex;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 20px;
            }
            .head {
              height: unset;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 25px;
              img {
                height: 30px;
                margin-bottom: 0;
                margin-right: 10px;
              }
              span {
                font-size: 30px;
                font-weight: bold;
                text-align: center;
              }
            }
            .desc {
              display: flex;
              margin-right: 20px;
              font-size: 14px;
              line-height: 2;
            }
          }
          &:hover {
            .header {
              transform: rotateY(180deg);
            }
            .expanded {
              opacity: 1;
              transform: rotateY(0deg);
            }
          }
        }
      }
    }
  }
  @include mdportrait {
    .pageWrap {
      .heroSection {
        flex: unset;
        max-height: unset;
        padding: 90px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logoArea {
          flex: unset;
          align-items: center;
          .subTitle {
            display: none;
          }
          .title {
            font-size: 45px;
            font-weight: bold;
            text-align: center;
            span {
              font-size: 70px;
              color: $app-color;
            }
          }
          .desc {
            display: block;
            width: 90%;
            line-height: 2;
            text-align: center;
          }
          .btns {
            width: 75%;
            margin-top: 0;
            flex: 0 0 60px;
            height: unset;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .btnReg,
            .btnLearn {
              margin: 10px 0;
              flex: unset;
              height: 60px;
            }
          }
        }
        .indMap {
          display: none;
        }
      }
      .aboutMock,
      .footerMenu,
      .footerCopyright,
      .stickyFooter,
      .founders {
        display: none;
      }
      .whyIndianOtc {
        padding: 30px;
        .titleWhy {
          font-size: 26px;
        }
        .reasonList {
          padding: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: unset;
          justify-content: space-between;
          .reasonCard {
            flex: unset;
            height: 180px;
            margin: 15px 0;
            @include btn-animate;
            padding-left: 40px;
            .bar {
              display: flex;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 20px;
            }
            .header {
              height: unset;
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: 10px;
              transform: rotateY(0deg) !important;
              img {
                height: 23px;
                margin-bottom: 0;
                margin-right: 10px;
              }
              span {
                font-size: 24px;
                font-weight: bold;
                text-align: center;
              }
            }
            .desc {
              display: flex;
              margin-right: 20px;
              font-size: 12px;
              line-height: 2;
            }
            .expanded {
              display: none;
            }
          }
        }
      }
    }
  }
}
