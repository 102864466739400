@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.chartWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $border-color;
  .chartHead {
    flex: 0 0 40px;
    border-bottom: 1px solid $border-color;
  }
  .chartContainer {
    height: 0;
    flex: 1;
    overflow: hidden;
    display: flex;
    .chart {
      flex: 1;
    }
  }
}
