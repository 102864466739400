@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.newsCarousel {
  display: flex;
  margin: 40px 0;
  .newsList {
    width: 0;
    flex: 1;
    display: flex;
    overflow-x: auto;
    margin-bottom: 30px;
    .newsCard {
      flex: 0 0 19%;
      width: 19%;
      margin-right: 1.2%;
      min-width: 280px;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        transform: scale(0.95);
      }
      &:last-of-type {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 15px;
      }
      .source {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 4px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
