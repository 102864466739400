@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.virtualProspectusVaults {
  height: 0;
  flex: 1;
  display: flex;
  .assetList {
    flex: 1;
    display: flex;
    flex-direction: column;
    .head {
      height: 60px;
      padding: 0 70px;
      display: flex;
      border-bottom: 1px solid $border-color;
      .asset,
      .balance,
      .ch24,
      .usd {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 600;
        color: $primary-color;
      }
      .asset {
        flex: 1;
      }
      .balance,
      .ch24,
      .usd {
        flex: 0 0 18%;
        max-width: 18%;
        justify-content: center;
      }
    }
    .coinList {
      height: 0;
      flex: 1;
      overflow-y: auto;
      .coinItem {
        cursor: pointer;
        height: 110px;
        padding: 0 70px;
        display: flex;
        opacity: 0.4;
        .asset {
          flex: 1;
          display: flex;
          align-items: center;
          .coin {
            height: 45px;
            width: 45px;
            object-fit: contain;
            margin-right: 15px;
          }
          .nameDetail {
            display: flex;
            flex-direction: column;
            color: $primary-color;
            .name {
              font-weight: 600;
            }
            .symbol {
              font-size: 13px;
            }
          }
        }
        .balance,
        .ch24,
        .usd {
          flex: 0 0 18%;
          max-width: 18%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .usd {
          font-weight: 600;
          color: $primary-color;
        }
        &:hover {
          opacity: 1;
        }
        &.true {
          opacity: 1;
        }
        &.select {
          opacity: 1;
          background: rgba(178, 206, 226, 0.17);
        }
      }
    }
  }
  .vaultsSidebar {
    border-left: 1px solid $border-color;
    flex: 0 0 600px;
    display: flex;
    flex-direction: column;
    .header {
      height: 60px;
      border-bottom: 1px solid $border-color;
      display: flex;
      .serchInput {
        width: 0;
        flex: 1;
        border: none;
        background: none;
        padding: 0 40px;
        font-size: 18px;
      }
      .btnBox {
        flex: 0 0 60px;
        border-left: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.3;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        &.true {
          opacity: 1;
        }
      }
    }
    .notSelected {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      .transactionTitle {
        font-size: 4vh;
        font-weight: 600;
      }
      .transactionSubTitle {
        font-size: 2vh;
      }
    }
    .transactionList {
      height: 0 !important;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .txnItem {
        flex: 0 0 110px;
        border-bottom: 1px solid $border-color;
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .timeAmt {
          color: $primary-color;
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 10px;
          .debit {
            color: #ff2d55;
          }
        }
        .dateUsd {
          font-size: 15px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
