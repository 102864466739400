@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.textSide {
  flex: 0 0 30%;
  background: #f2a9001e;
  display: flex;
  align-items: center;
  padding: 5%;
  .title {
    line-height: 1.5;
    font-size: 60px;
    font-weight: bold;
  }
}
.contentCards {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;
  .logo {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 24px;
    font-weight: 600;
  }
  .btns {
    margin: 40px 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    .btnGoBack,
    .btnProceed {
      flex: 0 0 49%;
      border: 1px solid $border-color;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: 600;
      text-decoration: none;
      color: $primary-color;
      @include btn-animate;
    }
    .btnProceed {
      background: $app-color;
      color: white;
      @include btn-animate(white);
    }
  }
}
