@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.institutionalPreRegPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $app-color;
  z-index: 0;
}
