@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.vPAnalysisContent {
  height: 0;
  flex: 1;
  display: flex;
  .coinContent {
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    .coinDetail {
      padding: 40px 10%;
      .coinHead {
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: 600;
        img {
          height: 32px;
          margin-right: 8px;
        }
        .coinName {
          font-size: 30px;
        }
        .coinValue {
          margin-left: auto;
          font-size: 26px;
          display: flex;
          align-items: center;
          .coinBtn {
            margin-left: 10px;
            border: 1px solid $border-color;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            height: fit-content;
            cursor: pointer;
            img {
              height: 14px;
              width: 14px;
            }
            &:hover {
              background: $border-color;
            }
          }
        }
      }
      .desc {
        margin-top: 10px;
        line-height: 1.8;
      }
    }
    .vpCandleHead {
      flex: 0 0 60px;
      border: solid $border-color;
      border-width: 1px 0;
      display: flex;
      .coinView {
        flex: 0 0 32%;
        display: flex;
        .rate {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .coin {
          height: 60px;
          width: 60px;
          border: solid $border-color;
          border-width: 0 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 65%;
            width: 65%;
          }
        }
        &.true {
          font-weight: 600;
          .rate {
            border-bottom: 1px solid #000;
          }
        }
      }
      .marketView {
        flex: 1;
        border-right: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        transition: all 400ms ease;
        cursor: pointer;
        .against {
          font-size: 12px;
          font-weight: 400;
        }
        &:hover {
          font-size: 22px;
        }
      }
    }
    .vpCandleChart {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      .chart-container {
        height: 0;
        flex: 1;
      }
      .days {
        height: 26px;
        width: 52px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
      .logo {
        position: absolute;
        bottom: 52px;
        right: 30px;
        height: 30px;
        width: auto;
        z-index: 2;
        cursor: pointer;
      }
      .settingsBtn {
        border: 1px solid $border-color;
        border-top: none;
        height: 26px;
        width: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-top: 1px solid $border-color;
      height: 65px;
    }
  }
  .statsContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .selectedStats {
      padding: 40px 100px 20px 100px;
      .title {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 24px;
        font-weight: 600;
      }
      .date {
        font-size: 14px;
      }
    }
    .statsContainer {
      flex: 1;
      position: relative;
      perspective: 1000px;
      transform-style: preserve-3d;
      overflow: hidden;
    }
  }
  .mobileStats {
    display: none;
  }
}
