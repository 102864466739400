@import "../../static/scss/colors";
@import "../../static/scss/mixin";
@import "../../static/scss/hamburgers";

.topNavbar1 {
  display: flex;
  flex: 0 0 50px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.topNavbar {
  display: flex;
  flex: 0 0 75px;
  border-bottom: 1px solid $border-color;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-top: 50px;
  width: 100%;
  .navBar {
    z-index: 4;
    flex: 1;
    display: flex;
    align-items: center;
    background: white;
    .hamburger {
      display: none;
    }
    .logoWrap {
      height: 100%;
      width: 383px;
      display: flex;
      align-items: center;
      border-right: 1px solid $border-color;
      .logo {
        margin-left: 80px;
        height: 50%;
        transition: 400ms all ease;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .searchWrap {
      border-right: 1px solid #e7e7e7;
      width: 440px;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-right: 0;
      .icon {
        height: 18px;
        width: auto;
        opacity: 0.6;
      }
      input {
        height: 100%;
        flex: 1;
        border: none;
        background: none;
        padding: 0 10px;
        font-weight: 400;
        font-size: 17px;
        &::placeholder {
          color: $placeholder-color;
          font-weight: 500;
        }
      }
    }
    .menu {
      height: 100%;
      width: calc(100% - 727px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menuItem {
        height: 100%;
        width: 16.66%;
        border-right: 1px solid $border-color;
        font-size: 16px;
        // margin-right: 40px;
        cursor: pointer;
        font-weight: 400;
        color: $primary-color;
        text-decoration: none;
        padding: 10px;
        // margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        // &:last-of-type {
        //   margin-right: 80px;
        // }
        .dp {
          height: 40px;
          width: 40px;
          border-radius: 20px;
        }
        .caret {
          margin-left: 5px;
          height: 5px;
          width: auto;
          transition: all 400ms ease;
          transform-origin: center center;
          &.true {
            transform: rotate(180deg);
          }
        }
        span {
          padding: 5px 2px;
          position: relative;
          .bar {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 3px;
            margin: auto;
            background: $app-color;
          }
        }
        &:hover {
          transform: scale(1.02);
        }
        &.true {
          font-weight: bold;
          transform: scale(1.05);
        }
        &.dis {
          opacity: 0.5;
        }
      }
      .menuBtnApp {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        width: 120px;
        border: 1px solid $border-color;
        background: white;
        color: $primary-color;
        margin-right: 50px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        @include btn-animate;
      }
    }
    .cornerMenu {
      height: 100%;
      flex: 0 0 75px;
      border-left: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .img {
        height: 40px;
        width: 40px;
      }
      .rainDropWrap {
        border: 1px solid $border-color;
        border-radius: 10px;
        top: 60px;
        right: 50px;
        height: 70px;
        padding: 0 20px;
        position: absolute;
        background: white;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 30px;
          padding-bottom: 5px;
        }
      }
    }
  }
  @include mdportrait {
    .navBar {
      .hamburger {
        display: flex;
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
      }
      .logoWrap {
        width: 100%;
        justify-content: center;
        .logo {
          margin: 0;
        }
      }
      .searchWrap,
      .menu {
        display: none;
      }
    }
  }
}
