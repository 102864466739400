@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.founderPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    .founderImageDetails {
      flex: 0 0 35%;
      display: flex;
      flex-direction: column;
      .founderImg {
        display: none;
      }
      .name {
        font-size: 60px;
        font-weight: bold;
        color: $primary-color;
        text-align: center;
        margin-top: 40px;
      }
      .post {
        font-size: 30px;
        font-weight: 600;
        color: $app-color;
        text-align: center;
      }
      .bgImg {
        width: auto;
        height: 80%;
        object-fit: contain;
        margin-top: auto;
      }
    }
    .pageContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 60px;
      .title {
        font-size: 36px;
        font-weight: 600;
      }
      .desc {
        width: 95%;
        font-weight: 300;
        font-size: 26px;
        line-height: 2;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      .readMore {
        font-weight: 500;
        font-size: 26px;
        cursor: pointer;
        margin-bottom: 1rem;
      }
      .hr {
        margin: 30px 0;
        border: none;
        border-bottom: 1px solid $border-color;
      }
      &.true {
        overflow-y: auto;
        .desc {
          text-overflow: unset;
          overflow: unset;
          display: block !important;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
  }
  @include mdportrait {
    .pageWrap {
      flex-direction: column;
      .founderImageDetails {
        flex: unset;
        display: flex;
        flex-direction: column;
        .founderImg {
          display: flex;
          position: relative;
          margin-bottom: 22%;
          .bgWrap {
            width: 100%;
            height: auto;
          }
          .dpMobile {
            position: absolute;
            top: 45%;
            bottom: -45%;
            left: 0;
            right: 0;
            margin: auto;
            height: 100%;
            width: auto;
            border-radius: 10px;
            box-shadow: 0 0 20px $primary-color;
          }
        }
        .name {
          font-size: 40px;
          margin: 0;
        }
        .post {
          font-size: 20px;
          margin-bottom: 20px;
        }
        .bgImg {
          display: none;
        }
      }
      .pageContent {
        flex: 1;
        padding: 20px;
        overflow: auto;
        .title {
          display: none;
        }
        .desc {
          width: 90%;
          font-size: 16px;
          text-align: center;
          margin: 0 auto;
          text-overflow: unset;
          overflow: unset;
          display: block !important;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        .readMore {
          display: none;
        }
      }
    }
  }
}
