@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.fundsMarketSubTypesPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .topArea {
    height: 0;
    flex: 0 0 20%;
    background-color: #1ba27a;
    max-height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .cardSub {
      width: 15%;
      height: 60%;
      background: white;
      border: 1px solid $border-color;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .value {
        font-size: 30px;
        font-weight: bold;
      }
      .label {
        font-size: 17px;
      }
      &.company {
        left: 80px;
      }
      &.mcap {
        right: 80px;
      }
    }
    .centerLogoName {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: translateY(50%);
      width: 25%;
      border: 1px solid $border-color;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        height: 50px;
        width: 50px;
        margin-right: 20px;
      }
      span {
        font-size: 40px;
        font-weight: bold;
      }
    }
  }
  .spacer {
    flex: 0 0 15%;
  }

  @include mdportrait {
  }
}
