@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.founderMoreCarousel {
  height: 0;
  flex: 1;
  display: flex;
  margin-top: 30px;
  margin-right: -60px;
  .newsList {
    width: 0;
    flex: 1;
    display: flex;
    overflow-x: auto;
    .newsCard {
      width: fit-content;
      margin-right: 2%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      flex: 0 0 26%;
      width: 26%;
      &:hover {
        transform: scale(0.95);
      }
      .img {
        flex: 1;
        height: 0;
        width: auto;
        object-fit: contain;
        border-radius: 10px;
        margin-bottom: 15px;
      }
      .source {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 4px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &.true {
    flex: 0 0 33%;
  }
  @include mdportrait {
    flex-direction: column;
    margin: 0;
    height: 100%;
    flex: unset;
    .newsList {
      width: 100%;
      flex-direction: column;
      overflow: unset;
      .newsCard {
        width: 100%;
        margin: 0;
        flex: unset;
        margin-bottom: 30px;
        .img {
          width: 100%;
          height: auto;
        }
        .source {
          font-size: 14px;
        }
        .title {
          font-size: 18px;
        }
      }
    }
  }
}
