@import "../../../static/scss/colors";
@import "../../../static/scss/mixin";

.vpCandleChart {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .chartContainer {
    height: 0;
    flex: 1;
  }
  .days {
    height: 26px;
    width: 52px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .logo {
    position: absolute;
    bottom: 52px;
    right: 30px;
    height: 30px;
    width: auto;
    z-index: 2;
    cursor: pointer;
  }
  .settingsBtn {
    border: 1px solid $border-color;
    border-top: none;
    height: 26px;
    width: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    svg {
      height: 60%;
    }
  }
}
