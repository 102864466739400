@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.orderBookWrap {
  flex: 0 0 24%;
  display: flex;
  flex-direction: column;
  .orderBookHead {
    flex: 0 0 40px;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-size: 15px;
    font-weight: bold;
    span {
      flex: 0 0 33.33%;
      text-align: center;
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
  .stepsRed,
  .stepsGreen {
    height: 0;
    flex: 1;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      padding: 0 40px;
      font-size: 13px;
      height: 0;
      flex: 1;
      font-weight: 600;
      align-items: center;
      span {
        flex: 0 0 33.33%;
        text-align: center;
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
  .stepsRed {
    span {
      color: red;
    }
  }
  .stepsGreen {
    span {
      color: green;
    }
  }
  .centerValue {
    flex: 0 0 40px;
    border-bottom: 1px solid $border-color;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 600;
  }
}
