@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.marketTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  .header {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    height: 30px;
    background: white;
    padding: 0 40px 0 20px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: sticky;
    z-index: 1;
    .btnFilter {
      position: absolute;
      height: 15px;
      width: 15px;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      transition: all 400ms ease;
      &.true {
        transform: rotate(180deg);
      }
    }
    .no,
    .assets,
    .price,
    .roiPercent,
    .roiInr,
    .marCap,
    .volume,
    .supply {
      display: flex;
      align-items: center;
    }
    .price,
    .roiPercent,
    .roiInr,
    .marCap,
    .volume,
    .supply {
      justify-content: flex-end;
    }
    .assets {
      flex: 0 0 30%;
    }
    .price {
      flex: 0 0 15%;
    }
    .roiPercent {
      flex: 0 0 12%;
    }
    .roiInr {
      flex: 0 0 18%;
    }
    .marCap {
      flex: 0 0 25%;
    }
    .volume,
    .supply {
      display: none;
    }
    &.active {
      border-top: 1px solid $primary-color;
      background: $primary-color;
      color: white;
      margin: 0 -80px;
      padding: 0 100px 0 80px;
    }
    &.true {
      .assets {
        flex: 0 0 17%;
      }
      .volume,
      .supply {
        display: flex;
      }
      .price {
        flex: 0 0 13%;
      }
      .roiPercent {
        flex: 0 0 10%;
      }
      .roiInr {
        flex: 0 0 13%;
      }
      .marCap {
        flex: 0 0 18%;
      }
      .volume {
        flex: 0 0 14%;
      }
      .supply {
        flex: 0 0 15%;
      }
    }
  }
  .filtersWrap {
    flex: 0 0 75px;
    border-bottom: 1px solid $border-color;
    padding-left: 20px;
    display: flex;
    .filters {
      width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .filter {
        border-radius: 8px;
        padding: 0 15px;
        height: 36px;
        border: 1px solid $border-color;
        display: flex;
        font-size: 22px;
        margin-right: 20px;
        align-items: center;
        .label {
          font-size: 13px;
          padding-right: 15px;
        }
        .value {
          display: flex;
          align-items: center;
          height: 100%;
          border-left: 1px solid $border-color;
          padding-left: 15px;
          @include btn-animate;
        }
        img {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .input {
      display: none;
      background: none;
      border: none;
      border-left: 1px solid $border-color;
      flex: 0 0 28%;
      padding: 0 80px;
    }
  }
  .marketsList {
    flex: 1;
    display: flex;
    flex-direction: column;
    .marketItem {
      flex: 0 0 120px;
      display: flex;
      padding: 0 40px 0 20px;
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 400ms ease;
      @include btn-animate(whitesmoke);
      &:hover {
        background: whitesmoke;
      }
      .no,
      .assets,
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
        }
      }
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        justify-content: flex-end;
      }
      .assets {
        flex: 0 0 30%;
        display: flex;
        img {
          height: 25px;
          width: 25px;
          margin-right: 8px;
        }
        .name {
          font-weight: 600;
        }
        .names {
          flex: 1;
          line-height: 1;
          font-size: 17px;
          .name {
            font-weight: 600;
          }
          .symbol {
            margin-top: 2px;
            font-size: 10px;
            display: flex;
            align-items: center;
            img {
              height: 11px;
              width: 11px;
              margin: 0 3px;
            }
            strong {
              font-weight: 700;
            }
          }
        }
      }

      .price {
        flex: 0 0 15%;
      }
      .roiPercent {
        flex: 0 0 12%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .roiInr {
        flex: 0 0 18%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .marCap {
        flex: 0 0 25%;
      }
      .volume,
      .supply {
        display: none;
      }
      &.true {
        .volume,
        .supply {
          display: flex;
        }
        .assets {
          flex: 0 0 17%;
        }
        .price {
          flex: 0 0 13%;
        }
        .roiPercent {
          flex: 0 0 10%;
        }
        .roiInr {
          flex: 0 0 13%;
        }
        .marCap {
          flex: 0 0 18%;
        }
        .volume {
          flex: 0 0 14%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          .value {
            font-size: 17px;
          }
          .symbol {
            font-size: 12px;
          }
        }
        .supply {
          flex: 0 0 15%;
        }
      }
    }
    .marketItemBond {
      flex: 0 0 160px;
      display: flex;
      padding: 0 40px 0 20px;
      font-size: 19px;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 400ms ease;
      @include btn-animate(whitesmoke);
      &:hover {
        background: whitesmoke;
      }
      .no,
      .assets,
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        display: flex;
        align-items: center;
        line-height: 1.1;
        span {
          font-weight: 400;
          font-size: 15px;
        }
      }
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
      }
      .assets {
        flex: 0 0 30%;
        display: flex;
        justify-content: flex-start;
        img {
          height: 45px;
          width: 45px;
          margin-right: 8px;
        }
        .names {
          flex: 1;
          font-size: 17px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }

      .price {
        flex: 0 0 15%;
      }
      .roiPercent {
        flex: 0 0 12%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .roiInr {
        flex: 0 0 18%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .marCap {
        flex: 0 0 25%;
      }
      .volume,
      .supply {
        display: none;
      }
      &.true {
        .volume,
        .supply {
          display: flex;
        }
        .assets {
          flex: 0 0 17%;
        }
        .price {
          flex: 0 0 13%;
        }
        .roiPercent {
          flex: 0 0 10%;
        }
        .roiInr {
          flex: 0 0 13%;
        }
        .marCap {
          flex: 0 0 18%;
        }
        .volume {
          flex: 0 0 14%;
        }
        .supply {
          flex: 0 0 15%;
        }
      }
    }
  }
}
