@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .patnershipMaster{
      height: 100%;
      width: 100%;
      color: #5F6163;
      overflow: hidden;
      display: flex;
      .selectoptions{
        height: 100%;
        width: 787px;
        border-right: 0.5px solid #EFEFEF;
      }
      .abcd{
        width: calc(100% - 820px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .pMaintitle{
          font-weight: 800;
          font-size: 45px;
          line-height: 45px;
          padding: 0px 60px 0 60px;
        }
      }
      .partnershipSection{
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding: 0px 60px 0 60px;
        .psubhead{
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .ptextbox{
          border: 0.5px solid #EFEFEF;
          padding: 15px;
          border-radius: 7px;
        }
        .pinput{
          border: none;
          height: 40px;
          width: 100%;
          border-radius: 7px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .ptextarea{
          resize: none;
          border: none;
          height: 140px;
          width: 100%;
          border-radius: 7px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .pinput .ptextarea::placeholder {
          opacity: 0.35;
        }
        
        .pinput .ptextarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
            opacity: 0.35;
        }
          
        .pinput .ptextarea::-ms-input-placeholder { /* Microsoft Edge */
            opacity: 0.35;
        }

        /* Chrome, Safari, Edge, Opera */
        .pinput::-webkit-outer-spin-button,
        .pinput::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .pinput[type=number] {
          -moz-appearance: textfield;
        }

        .multiselectbox{
          display: flex;
          flex-wrap: wrap;
        }

        .eachselect{
          cursor: pointer;
          width: 22%;
          height: 80px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // justify-content: center;
          border: 0.5px solid #EFEFEF;
          // padding: 15px;
          border-radius: 7px;
          margin: 10px;
        }
        .selectedbox{
          border: 2px solid #F2A900;
        }
        .cardtitle{
          height: 80px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .finalsubmit{
          width: 226px;
          height: 66px;
          margin: 20px 0;
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #F2A900;
          border-radius: 10px;
          cursor: pointer;
        }
        }
    }
  }
}

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.finalBox{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirmationbox{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.endbox{
  background-color: white;
  height: 50%;
  width: 80%;
  border-radius: 35px;
}
.imgbox{
  height: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.otclogo{
  height: 100%;
  width: 350px;
}
.textbox{
  padding: 70px;
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.confirmtext{
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}

.buttonBox{
  height: 20%;
  width: 100%;
  display: flex;
  font-weight: 800;
  font-size: 20px;
  line-height: 40px;
  bottom: 0;
}

.homebtn{
  width: 50%;
  background-color: white;
  color: #5F6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 35px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @include btn-animate(#5f6163);
}


.inquiry{
  width: 50%;
  background-color: #F2A900;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom-right-radius: 35px;
  cursor: pointer;
  @include btn-animate(white);
}
