@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.sidebar {
  flex: 0 0 380px;
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  .tabs {
    flex: 0 0 60px;
    border-bottom: 1px solid $border-color;
    display: flex;
    padding: 10px 0;
    .tab {
      width: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      transition: all 400ms ease;
      &:first-of-type,
      &:nth-of-type(2) {
        border-right: 1px solid $border-color;
      }
      &:hover,
      &.true {
        font-weight: bold;
      }
    }
  }
  .marketContent {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    .btnSwitch {
      flex: 0 0 50px;
      display: flex;
      border: 1px solid $border-color;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 30px;
      .btn {
        width: 0;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        @include btn-animate;
        &.true {
          @include btn-animate(transparent);
          background: $primary-color;
          color: white;
        }
      }
    }
    .inputWrap {
      flex: 0 0 70px;
      display: flex;
      border: 1px solid $border-color;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 40px;
      align-items: center;
      padding: 0 20px;
      font-size: 16px;
      .label {
        font-weight: 500;
      }
      .input {
        width: 0;
        flex: 1;
        text-align: right;
        font-size: 17px;
        padding: 0 20px;
        border: none;
        background: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .symbol {
        font-weight: bold;
      }
    }
    .btnFooter {
      flex: 0 0 60px;
      display: flex;
      border-radius: 12px;
      overflow: hidden;
      margin-top: auto;
      background: $primary-green;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 600;
      @include btn-animate(white);
      &.true {
        background: $primary-red;
      }
    }
  }
}
