@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    overflow: auto;
    .pageContent {
      flex: 1;
      padding: 40px 80px;
      display: flex;
      flex-direction: column;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleSubTitle {
          display: flex;
          flex-direction: column;
          flex: 1;
          .title {
            font-size: 36px;
            font-weight: 600;
          }
          .subTitle {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .switchWrap {
          height: 30px;
          display: flex;
          align-items: center;
          .country {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              height: 22px;
              width: 22px;
              border-radius: 11px;
            }
            span {
              font-weight: 600;
              font-size: 20px;
            }
          }
          .switch {
            cursor: pointer;
            height: 24px;
            width: 45px;
            border-radius: 12px;
            border: 2px $primary-color solid;
            margin: 0 6px;
            padding: 2px;
            display: flex;
            align-items: center;
            position: relative;
            .switchControll {
              height: 16px;
              width: 16px;
              border-radius: 8px;
              background: $primary-color;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 3px;
              transition: all 400ms ease;
            }
            &.true {
              .switchControll {
                left: 22px;
              }
            }
          }
        }
      }
    }
  }
}

.searchWrap {
  flex: 1;
  height: 45%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid $border-color;
  height: 100%;
  // margin-right: 50px;
  .icon {
    height: 18px;
    width: auto;
    opacity: 0.6;
  }
  input {
    height: 100%;
    flex: 1;
    border: none;
    background: none;
    padding: 0 10px;
    font-weight: 400;
    font-size: 17px;
    &::placeholder {
      color: $placeholder-color;
      font-weight: 500;
    }
  }
}
