@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .contactMaster{
      height: 100%;
      width: 100%;
      color: #5F6163;
      overflow: hidden;
      .titlesection{
        width: 30%;
        margin: 1% 35% 0 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .mainTitle{
          font-weight: 800;
          font-size: 45px;
          line-height: 40px;
        }
        .mainDesc{
          font-weight: 300;
          font-size: 15px;
          line-height: 35px;
        }
      }
      .querysection{
        width: 90%;
        margin: 10px 5%;
        display: inline-flex;
        flex-wrap: wrap;
      }
      .eachcard{
        cursor: pointer;
        height: 170px;
        margin: 30px 1.5%;
        width: 30.33%;
        padding: 30px;
        border-top: 0.5px solid #EFEFEF;
        border-left: 15px solid  #F2A900;
        border-right: 0.5px solid #EFEFEF;
        border-bottom: 0.5px solid #EFEFEF;
        border-radius: 15px;
        .queryTitle{
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
        .queryDesc{
          font-weight: 300;
          font-size: 12px;
          line-height: 27px;
        }
      }
      .eachcard:hover{
        background-color: #f5f5f5;
        transform: scale(1.1);
        transition: .6s ease;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }
  }
}
