@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.fundsMarketTypesPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .topLogoWrap {
    height: 0;
    flex: 0 0 20%;
    background-image: url("../../static/images/backgrounds/fundsBg.jpeg");
    background-position: center center;
    background-size: cover;
    max-height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50%;
      width: auto;
    }
  }
  .cardList {
    height: 0;
    flex: 1;
    padding: 50px 70px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    .card {
      height: 65%;
      flex: 0 0 32%;
      margin: 20px 0;
      border-radius: 20px;
      border: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      color: $primary-color;
      text-decoration: none;
      transition: all 400ms ease;
      .cs {
        font-size: 24px;
        font-weight: 600;
        margin: auto;
      }
      .header {
        flex: 0 0 80px;
        background: #1ba27a;
        position: relative;
        .cardLogo {
          position: absolute;
          left: 30px;
          bottom: -40px;
          height: 80px;
          width: 80px;
          border-radius: 12px;
          border: 1px solid $border-color;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 50px;
            width: 50px;
          }
        }
        .btnInvest {
          right: 30px;
          bottom: -20px;
          height: 40px;
          width: 120px;
          border-radius: 6px;
          border: 1px solid $border-color;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 19px;
          font-weight: 600;
          @include btn-animate;
          position: absolute;
        }
      }
      .content {
        margin-top: 40px;
        padding: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .cardTitle {
          display: flex;
          justify-content: space-between;
          font-size: 25px;
          font-weight: 700;
        }
        .cardDesc {
          line-height: 2;
          flex: 1;
        }
        .values {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          .itm {
            flex: 0 0 30%;
            text-align: center;
            &:first-of-type {
              text-align: left;
            }
            &:last-of-type {
              text-align: right;
            }
            .label {
              font-size: 17px;
              font-weight: 500;
            }
            .value {
              font-size: 25px;
              font-weight: bold;
              &.true {
                color: $primary-green;
              }
            }
          }
        }
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @include mdportrait {
  }
}

.searchWrap {
  flex: 1;
  height: 45%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid $border-color;
  height: 100%;
  margin-right: 50px;
  .icon {
    height: 18px;
    width: auto;
    opacity: 0.6;
  }
  input {
    height: 100%;
    flex: 1;
    border: none;
    background: none;
    padding: 0 10px;
    font-weight: 400;
    font-size: 17px;
    &::placeholder {
      color: $placeholder-color;
      font-weight: 500;
    }
  }
}
