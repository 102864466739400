@import "../../../static/scss/colors";
@import "../../../static/scss/mixin";

.content {
  margin-left: 100px;
  margin-top: 2px;
  width: 60%;
  height: 95%;
  padding: 30px;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: transform 0.4s ease;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(0, 0, 0);
  background: white;
  z-index: 3;
  .stats {
    position: absolute;
    left: 0;
    right: 0;
    top: -60px;
    order: 1;
    .title {
      font-size: 24px;
      font-weight: 600;
    }
    .date {
      font-size: 14px;
    }
    &.true {
      order: 0;
      .title {
        font-size: 29px;
      }
      .date {
        font-size: 16px;
      }
    }
  }
  .supplyList {
    flex: 0 0 60px;
    display: flex;
    overflow-x: auto;
    .supply {
      min-width: fit-content;
      border: 1px solid $border-color;
      display: flex;
      flex: 0 0 32%;
      margin-right: 2%;
      padding: 0 15px;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      img {
        height: 45%;
        width: auto;
        margin-right: 6px;
      }
      &.true {
        border-color: $primary-color;
      }
      &:hover {
        * {
          transform: scale(1.05);
        }
      }
    }
  }
  .donutNcard {
    height: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .donutWrap {
      flex: 0 0 50%;
      display: flex;
      position: relative;
      .donutSvg {
        margin: auto;
        width: 85%;
        height: auto;
      }
      .centreDonut {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background: white;
        border: 1px solid $border-color;
        transition: all 400ms ease;
        transform: scale(0);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        &.true {
          transform: scale(1);
        }
      }
    }
    .donutCards {
      height: 80% !important;
      width: 0;
      flex: 0 0 45%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .cardHead {
        color: $primary-color;
        font-weight: 600;
        padding: 10px 0;
        top: 0;
        position: sticky;
        background: white;
        z-index: 1;
      }
      .detailCard {
        zoom: 0.95;
        border: 1px solid $border-color;
        border-left-width: 8px;
        flex: 0 0 70px;
        margin-bottom: 20px;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        opacity: 0.3;
        .sold {
          font-size: 13px;
        }
        .value {
          font-size: 18px;
          font-weight: 600;
        }
        .coin {
          position: absolute;
          right: 30px;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 30px;
          width: 30px;
          object-fit: contain;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
        &.true {
          opacity: 1;
        }
      }
    }
  }
  .footer {
    flex: 0 0 70px;
    border-top: 1px solid $border-color;
    margin: 0 -30px;
    display: flex;
    .total {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      font-weight: 600;
      font-size: 20px;
    }
    .coin {
      flex: 0 0 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $border-color;
      cursor: pointer;
      img {
        transition: all 400ms ease;
        height: 40px;
        width: 40px;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .returns {
    height: 0;
    flex: 1;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      .date {
        font-size: 15px;
        font-weight: 400;
      }
    }
    .returnCard {
      flex: 0 0 32%;
      border: 1px solid $border-color;
      margin-bottom: 3%;
      border-left-width: 15px;
      display: flex;
      align-items: center;
      .percent,
      .value {
        height: 80%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        color: $primary-color;
      }
      .percent {
        flex: 0 0 30%;
        border-right: 1px solid $border-color;
      }
    }
  }
  .sentiment {
    margin: 40px 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    .sentimentWrap {
      flex: 0 0 48%;
      display: flex;
      flex-direction: column;
      .sentiCard {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid $border-color;
        .value {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .text {
            font-size: 30px;
            font-weight: 600;
          }
        }
        .moreData {
          border-top: 1px solid $border-color;
          flex: 0 0 50px;
          max-height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          color: $primary-color;
          cursor: pointer;
          img {
            margin-left: 6px;
            height: 12px;
          }
          * {
            transition: all ease 400ms;
          }
          &:hover {
            * {
              transform: scale(1.05);
            }
          }
        }
      }
      .btnSenti {
        flex: 0 0 55px;
        max-height: 55px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        transition: all 400ms ease;
        &:hover {
          font-size: 22px;
        }
        &.red {
          background: #ee5253;
        }
        &.green {
          background: #00bd9a;
        }
      }
    }
  }
  &.i0 {
    .stats {
      display: none;
    }
  }
  &.i1 {
    transform: translate3d(50%, 0, -200px);
    z-index: 2;
    .stats {
      .title {
        text-align: right;
      }
      .date {
        text-align: right;
      }
      &.true {
        order: 0;
        .title {
          font-size: 29px;
        }
        .date {
          font-size: 16px;
        }
      }
    }
  }
  &.i2 {
    transform: translate3d(100%, 0, -400px);
    z-index: 1;
    .stats {
      display: none;
    }
  }
  &.i3,
  &.i4 {
    transform: translate3d(150%, 0, -600px);
    z-index: 0;
    .stats {
      display: none;
    }
  }
}
