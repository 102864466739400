@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.fundsMarketPathsTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $border-color;
  margin: 0 80px;
  padding: 0 30px;
  border-radius: 20px; //20px 0 0;
  .tableHeader {
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    top: 0;
    z-index: 1;
    padding: 10px 0;
    color: $primary-color;
    border-bottom: 1px solid $border-color;
    .no {
      flex: 0 0 6%;
      text-align: center;
    }
    .asset {
      flex: 0 0 20%;
    }
    .price {
      flex: 0 0 12%;
    }
    .ch24 {
      flex: 0 0 14%;
    }
    .supply {
      flex: 0 0 11%;
    }
    .marCap {
      flex: 0 0 18%;
    }
    .volume24 {
      flex: 1;
    }
  }
  .coinItem {
    flex: 0 0 130px;
    padding: 20px 0;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    font-weight: 600;
    transition: all 400ms ease;
    .no {
      flex: 0 0 6%;
      text-align: center;
    }
    .asset {
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
      .detail {
        .coinName {
          font-size: 18px;
          font-weight: 600;
        }
        .coin {
          display: flex;
          align-items: center;
          font-size: 14px;
          .flag {
            height: 15px;
            width: 15px;
            margin: 0 4px;
          }
        }
      }
    }
    .price {
      flex: 0 0 12%;
    }
    .ch24 {
      color: $primary-green;
      &.true {
        color: $primary-red;
      }
    }
    .ch24 {
      flex: 0 0 14%;
    }
    .supply {
      flex: 0 0 11%;
    }
    .marCap {
      flex: 0 0 18%;
    }
    .volume24 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnTrade {
        padding: 8px 40px;
        margin-right: 30px;
        border-radius: 8px;
        border: 1px solid $border-color;
        @include btn-animate(white);
      }
    }
    &:hover {
      transform: scale(1.01);
    }
  }
}
