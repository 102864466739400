@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.coinBuyTable {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 0 80px;
  .tableHeader {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    top: 0;
    position: sticky;
    z-index: 1;
    margin: 0 -80px;
    padding: 10px 80px;
    background-color: $primary-color;
    color: white;
    .no {
      flex: 0 0 6%;
      text-align: center;
    }
    .asset {
      flex: 0 0 18%;
    }
    .price {
      flex: 0 0 12%;
    }
    .ch24 {
      flex: 0 0 9%;
    }
    .ch24Dis {
      flex: 0 0 11%;
    }
    .marCap {
      flex: 0 0 18%;
    }
    .volume24 {
      flex: 1;
    }
  }
  .coinItem {
    flex: 0 0 100px;
    padding: 20px 0;
    border-radius: 20px;
    border: 1px solid $border-color;
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 600;
    .no {
      flex: 0 0 6%;
      text-align: center;
    }
    .asset {
      flex: 0 0 18%;
      max-width: 18%;
      display: flex;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
      .coinName {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .price {
      flex: 0 0 12%;
    }
    .ch24,
    .ch24Dis {
      color: $primary-green;
      &.true {
        color: $primary-red;
      }
    }
    .ch24 {
      flex: 0 0 9%;
    }
    .ch24Dis {
      flex: 0 0 11%;
    }
    .marCap {
      flex: 0 0 18%;
    }
    .volume24 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnTrade {
        padding: 8px 40px;
        margin-right: 30px;
        border-radius: 8px;
        background: $primary-green;
        color: white;
        @include btn-animate(white);
        &.false {
          background-color: $primary-red;
        }
      }
    }
  }
}
