@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.terminalsPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    .mainContents {
      flex: 1;
      display: flex;
      flex-direction: column;
      .coinDetail {
        padding: 0 80px;
        flex: 0 0 60px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pair {
          font-size: 22px;
          font-weight: bold;
        }
        .property {
          .label {
            font-size: 12px;
          }
          .price {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
      .chartOrderBookWrap {
        flex: 0 0 55%;
        border-bottom: 1px solid $border-color;
        display: flex;
      }
      .topSignals {
        flex: 1;
        display: flex;
        .signals {
          border-left: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
        .traders,
        .signals,
        .orders {
          flex: 0 0 33.33%;
          display: flex;
          flex-direction: column;
          .tabs {
            flex: 0 0 45px;
            border-bottom: 1px solid $border-color;
            display: flex;
            padding: 10px 0;
            .tab {
              flex: 0 0 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              font-size: 14px;
              opacity: 0.7;
              cursor: pointer;
              transition: all 400ms ease;
              &:first-of-type {
                border-right: 1px solid $border-color;
              }
              &:hover,
              &.true {
                opacity: 1;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.searchWrapOut {
  flex: 1;
  height: 100%;
  border-right: 1px solid $border-color;
  display: flex;
  position: relative;
  z-index: 3;
  margin-right: 50px;
  .searchWrap {
    border-radius: 15px;
    background: white;
    z-index: 3;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .icon {
      height: 18px;
      width: auto;
      opacity: 0.6;
    }
    input {
      height: 100%;
      flex: 1;
      border: none;
      background: none;
      padding: 0 10px;
      font-weight: 400;
      font-size: 17px;
      &::placeholder {
        color: $placeholder-color;
        font-weight: 500;
      }
    }
  }
  .searchRes {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -480px;
    top: 0;
    background: white;
    z-index: 2;
    border-radius: 15px;
    padding: 100px 30px 0 30px;
    display: flex;
    flex-direction: column;
    .topListWrap {
      flex: 0 0 40px;
      display: flex;
      .topList {
        width: 0;
        flex: 1;
        display: flex;
        align-items: center;
        overflow-x: auto;
        .coinQuote {
          border: 1px solid $border-color;
          height: 30px;
          padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          border-radius: 6px;
          @include btn-animate;
          img {
            height: 20px;
            width: auto;
          }
          &.true {
            border: 1px solid $primary-color;
          }
        }
      }
    }
    .pairsList {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .pair {
        flex: 0 0 90px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        .pairAsset {
          flex: 0 0 40%;
          display: flex;
          align-items: center;
          font-weight: bold;
          .icon {
            height: 24px;
            width: 24px;
            margin-right: 5px;
          }
        }
        .value,
        .change {
          flex: 0 0 30%;
          font-weight: 600;
        }
      }
    }
  }
  .wrapOutline {
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $overlay-color-light;
  }
}
