@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import "_colors";
@import "mixin";

body {
  font-family: Montserrat !important;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  #root {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    * {
      font-family: Montserrat;
      outline: none;
      box-sizing: border-box;
    }
    @media (max-height: 1050px) {
      zoom: 0.95;
    }
    @include xxxl {
      zoom: 0.8;
    }
    @include xxl {
      zoom: 0.7;
    }
    @include xl {
      zoom: 0.6;
    }
    @include lg {
      zoom: 0.5;
    }
    @include mdlscape {
      zoom: 0.35;
    }
    @include smlscape {
      zoom: 0.25;
    }
    @include mdportrait {
      zoom: 1;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
