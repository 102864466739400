@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.otcSubMenuWrap {
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: all 400ms ease;
  margin-top:50px;
  .overlay {
    z-index: 1;
    background-color: $overlay-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .otcSubMenu {
    transition: all 400ms ease;
    border-top: 1px solid $border-color;
    z-index: 1;
    margin-top: 74px;
    height: 0;
    background-color: white;
    display: flex;
    overflow: hidden;
    .subMenuList {
      flex: 0 0 383px;
      max-width: 383px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      border-right: 1px solid $border-color;
      margin: 20px 0;
      .sideMenu {
        position: relative;
        display: flex;
        align-items: center;
        height: 0;
        flex: 0 0 20%;
        line-height: 1;
        cursor: pointer;
        padding: 0 80px;
        color: $primary-color;
        text-decoration: none;
        transition: all 400ms ease;
        width: 100%;
        font-weight: 600;
        img {
          height: 28px;
          width: 28px;
          margin-right: 8px;
          object-fit: contain;
        }
        span {
          font-size: 20px;
          font-weight: 4 00;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 4px;
          height: 70%;
          margin: auto;
          background: $app-color;
          display: none;
        }
        &.dis {
          opacity: 0.6;
        }
        &.true {
          span {
            font-weight: 700;
          }
          &::after {
            display: block;
          }
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }
    .tabDetailContent {
      padding: 30px 50px;
      padding-bottom: 0;
      width: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      .titlle {
        line-height: 1;
        font-size: 25px;
        font-weight: bold;
      }
      .subtitle {
        margin-top: 5px;
        font-size: 12px;
        height: 14px;
        display: flex;
        align-items: center;
        strong {
          text-decoration: underline;
          cursor: pointer;
          transition: all 400ms ease;
          margin-left: 2px;
          &:hover {
            margin-left: 3px;
            font-size: 13px;
          }
        }
      }
      .actionList {
        height: 0;
        flex: 1;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: auto;
        .action {
          flex: 0 0 30%;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          @include btn-animate;
          .icon {
            height: 50px;
            width: 50px;
            border-radius: 10px;
            border: 1px solid $border-color;
            margin-right: 10px;
            padding: 6px;
          }
          .detail {
            .actName {
              font-size: 20px;
              font-weight: 600;
            }
            .actDesc {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  &.true {
    height: 100%;
    .otcSubMenu {
      height: 455px;
      min-height: 455px;
    }
  }
}
