@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .startSection {
      flex: 1;
      padding: 40px 80px;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 60px;
        margin-bottom: 0;
      }
      .titleSubTitle {
        display: flex;
        flex-direction: column;
      }
      .btns {
        width: 250px;
        margin-top: 2%;
        .btnLearn {
          padding: 6%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          border-radius: 12px;
          border: 1px solid $primary-color;
          text-decoration: none;
          @include btn-animate;
        }
        .btnLearn {
          border-color: $app-color;
          background: $app-color;
          color: white;
          @include btn-animate(white);
        }
      }
      .description {
        width:100%;
        background-color:$app-color;
        height: 300px;
        margin-top: -130px;
        z-index: -1;
       }
      .indvcards{
        margin-top: 75px;
        height: 250px;
        width: 500px;
        border-radius: 20px;
        background-color: white;
        border: 1px solid $border-color;
     }
     .indvcards:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, 
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, 
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
     }
    }
    .aboutSection {
      flex: 1;
      padding: 40px 80px;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 40px;
        margin-bottom: 0;
      }
      .aboutCards {
        display: flex;
        justify-content: center;
        margin: 50px;
        width:100%;
        .indvcards{
          height: 280px;
          width: 485px;
          padding: 20px;
          background-color: white;
          border: 1px solid $border-color;
          border-radius: 20px;
          text-align: center;
          .title {
            font-size: 23px;
          }
          .description {
            margin-top: 30px;
            line-height: 2;
          }
          .button1 {
            margin-top: 10px;
            padding: 10px;
            width: 150px;
            border-radius: 15px;
            outline: none;
            border: 0;
            cursor: pointer;
            @include btn-animate(white);
          }
          .btntxt {
            color: white;
            font-size: 20px;
            font-family: 600;
            margin: 0;
            padding: 0;
          }
       }
       .indvcards:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, 
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, 
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
       }
      }
    }
    .underLine {
      margin-top: 80px;
      margin-bottom: 40px;
      border: 1px solid #E5E5E5;
    }
  }
}
