@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.getStartedPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  .textSide {
    flex: 0 0 30%;
    background: #f2a9001e;
    display: flex;
    align-items: center;
    padding: 5%;
    .title {
      line-height: 1.5;
      font-size: 65px;
      font-weight: bold;
    }
  }
  .contentCards {
    flex: 1;
    padding: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card {
      flex: 0 0 30%;
      max-height: 30%;
      border: 1px solid $border-color;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      color: $primary-color;
      text-decoration: none;
      @include btn-animate;
      .cardImg {
        height: 100%;
        width: auto;
      }
      .cardTexts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 60px;
        .cardTitle {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 18px;
        }
        .cardDesc {
          font-size: 20px;
          line-height: 2;
        }
      }
      &.dis {
        opacity: 0.4;
      }
    }
  }
}
