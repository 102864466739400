:root {
  --primary-color: #5f6163;
  --app-color: #f2a900;
}

$primary-color: var(--primary-color);
$app-color: var(--app-color);
$text-color: var(--primary-color);
$placeholder-color: #5f616380;
$vault-border-color: #e7e7e7;
$border-color: #e7e7e7;
$primary-green: #3ea254;
$primary-red: #ea0f0f;
$primary-blue: #182542;
$overlay-color: #000d;
$overlay-color-light: #0007;
