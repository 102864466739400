@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.otcBuySellPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    .pageContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      .subNavBar {
        flex: 0 0 70px;
        border-bottom: 1px solid $border-color;
        display: flex;
        .search {
          flex: 1;
          margin-left: auto;
          border: none;
          background: none;
          border-left: 1px solid $border-color;
          padding: 0 80px;
          font-size: 20px;
        }
      }
    }
  }
}
