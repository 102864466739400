@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.loadingAnimation {
  background-color: #fff8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 50%;
  }
}
