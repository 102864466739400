@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.prospectusPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .tabs {
    margin-top: auto;
    height: 60px;
    display: flex;
    border-top: 1px solid $border-color;
    overflow-y: scroll;
    position: relative;
    .tabItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 0 0 25%;
      font-size: 17px;
      font-weight: 500;
      position: relative;
      opacity: 0.4;
      &::before {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 0;
        content: "";
        height: 2px;
        background: $primary-color;
        transition: all 500ms ease;
      }
      &:hover {
        opacity: 0.8;
        font-size: 22px;
        font-weight: 600;
        &::before {
          background-color: $border-color;
          width: 100%;
        }
      }
      &.true {
        opacity: 1;
        font-size: 22px;
        font-weight: 600;
        &::before {
          width: 100%;
          background: $primary-color;
        }
      }
    }
  }
}
