@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.stickyFooter {
  flex: 0 0 65px;
  max-height: 65px;
  background: $app-color;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 19px;
  font-weight: 600;
  bottom: 0;
  position: sticky;
  .footerWrap {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .raindrops {
      height: 45%;
    }
    &.sb {
      justify-content: space-between;
      .left,
      .right {
        height: 35%;
      }
      .banner {
        display: flex;
        align-items: center;
        height: 100%;
        width: fit-content;
        .logo {
          height: 40%;
          margin-right: 10px;
        }
        span {
          font-size: 24px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}
