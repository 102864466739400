@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.homePage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .heroSection {
      // flex: 0 0 80%;
      // max-height: 80%;
      padding-bottom: 1%;
      padding-left: 80px;
      display: flex;
      justify-content: space-between;
      .logoArea {
        flex: 0 0 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .subTitle {
          font-size: 30px;
          font-weight: 600;
        }
        .title {
          font-size: 60px;
          font-weight: bold;
        }
        .desc {
          display: none;
        }
        .btns {
          width: 65%;
          margin-top: 30px;
          flex: 0 0 60px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          .btnReg,
          .btnLearn {
            flex: 0 0 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            border-radius: 12px;
            border: 1px solid $primary-color;
            text-decoration: none;
            @include btn-animate;
          }
          .btnLearn {
            border-color: $app-color;
            background: $app-color;
            color: white;
            @include btn-animate(white);
          }
        }
      }
      .indMap {
        height: 100%;
        width: auto;
      }
      .zoom {
        height: 50px;
      }
      .zoom1 {
        display: inline-block;
        height: 30px;
        width: 30px;
        overflow: hidden;
        background: #F3F4F5;
        border-radius: 30px;
        border: 0;
        cursor: pointer;
        box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
        transition: all 0.3s ease-out;
      }
      .text1 {
        display: none;
      }
      .arrow1 {
        height: 20px;
        width: 20px
      }
      .cards .indvcards:hover{
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        .zoom {
          transform: scale(1.5);
          transition: all 0.3s ease-in-out;
        }
        .text1 {
          margin-left: 9px;
          margin-top:10px;
          display: inline-flex;
          color: orange;
        }
        .zoom1 {
          height: 50px;
          width: 120px;
          background: #fffbf0;
          text-align: center;
        }
        .arrow1 {
          margin-left: 0.2rem;
          margin-top: 0.8rem;
          width: 20px
        }
      }
    }

    .totalscrollBody{
      // overflow-y: scroll;
      .mobfootersection{
        height: 100%;
        .engagebox{
          margin-left: 10%;
          .text{
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
          }
          .eachmedia{
            display: flex;
            border-radius: 10px;
            width: 50%;
            .mediaImg{
              margin-top: 15px;
              height: 20px;
              width: 20px;
              margin-right: 15px;
            }
            .mediaName{
              font-weight: 600;
              font-size: 12px;
              line-height: 25px;
            }
          }
          .eachmedia:active{
            background-color: #F2A900;
            color: white;
          }
          .servicedata{
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            .servicecollection{
              width: 45%;
            }
          }
        }
      }
      .mobrightssection{
        .rightsbox{
          margin-top: 10px;
          height: 50px;
          border-top: 0.5px solid #E5E5E5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 700;
          font-size: 13px;
          line-height: 25px;
        }
      }
    }
    
    .mobileheroSection{
      // overflow-y: scroll;
      padding-top: 60px;
      color:  #5F6163;
      .subTitle{
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        display: flex;
        justify-content: center;
        color:  #5F6163;
        margin-bottom: 10px;
      }
      .title{
        font-weight: 800;
        font-size: 45px;
        line-height: 60px;
        text-align: center;
        color:  #5F6163;
        .titlespan{
          color: #F2A900;
        }
      }
      .btns {
        width: 100%;
        margin-top: 20px;
        height: 46px;
        display: flex;
        justify-content: center;
        .btnReg,
        .btnLearn {
          width: 38%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          border-radius: 7px;
          border: 1px solid $primary-color;
          text-decoration: none;
          margin-left: 10px;
          @include btn-animate;
        }
        .btnLearn {
          border-color: $app-color;
          background: $app-color;
          color: white;
          @include btn-animate(white);
        }
      }
      .indMap {
        height: 100%;
        width: auto;
      }
      .zoom {
        height: 50px;
      }
      .zoom1 {
        display: inline-block;
        height: 30px;
        width: 30px;
        overflow: hidden;
        background: #F3F4F5;
        border-radius: 30px;
        border: 0;
        cursor: pointer;
        box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
        transition: all 0.3s ease-out;
      }
      .text1 {
        display: none;
      }
      .arrow1 {
        height: 20px;
        width: 20px
      }
      .cards .indvcards:focus{
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        .zoom {
          transform: scale(1.5);
          transition: all 0.3s ease-in-out;
        }
        .text1 {
          margin-left: 9px;
          margin-top:10px;
          display: inline-flex;
          color: orange;
        }
        .zoom1 {
          height: 50px;
          width: 120px;
          background: #fffbf0;
          text-align: center;
        }
        .arrow1 {
          margin-left: 0.2rem;
          margin-top: 0.8rem;
          width: 20px
        }
      }
      .mobstickyFooter {
        flex: 0 0 65px;
        max-height: 65px;
        background: $app-color;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        font-weight: 600;
        bottom: 0;
        position: -webkit-sticky;
        position: sticky;
        // margin-bottom: 20px;
        .footerWrap {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .raindrops {
            height: 190px;
            width: 190px;
          }
          &.sb {
            justify-content: space-around;
            .left,
            .right {
              margin-top: 5px;
              height: 20px;
              width: 20px;
            }
            // .left{
            //   padding-right: 2%;
            // }
            // .right{
            //   padding-left: 2%;
            // }
            .banner {
              display: flex;
              align-items: center;
              height: 100%;
              width: fit-content;
              .logo {
                height: 30px;
                width: 30px;
                margin-right: 10px;
              }
              span {
                font-size: 14px;
                font-weight: bold;
                color: white;
              }
            }
          }
        }
      }
    }

    .stickyFooter {
      flex: 0 0 65px;
      max-height: 65px;
      background: $app-color;
      padding: 20px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 19px;
      font-weight: 600;
      bottom: 0;
      position: sticky;
      .footerWrap {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .raindrops {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 1%;
          width: 15%;
          padding-bottom: 10px;
        }
        &.sb {
          justify-content: space-between;
          .left,
          .right {
            height: 20px;
            width: 30px;
          }
          .banner {
            display: flex;
            align-items: center;
            height: 50px;
            width: fit-content;
            .logo {
              height: 50px;
              width: 50px;
              margin-right: 10px;
            }
            span {
              font-size: 24px;
              font-weight: bold;
              color: white;
            }
          }
        }
      }
    }
    .allbelowsticky{
      width: 100%;
    }
    .footersection{
      margin-top: 45px;
      width: 100%;
      .engagebox{
        margin-left: 5%;
        .text{
          font-weight: 800;
          font-size: 28px;
          line-height: 25px;
        }
        .eachmedia{
          display: flex;
          border-radius: 10px;
          width: 90%;
          .mediaImg{
            margin-top: 20px;
            height: 30px;
            width: 30px;
            margin-right: 20px;
          }
          .mediaName{
            font-weight: 400;
            font-size: 22px;
            line-height: 25px;
          }
        }
        .eachmedia:hover{
          @include btn-animate(#5f6163);
        }
        .servicedata{
          width: 90%;
          display: flex;
          flex-wrap: wrap;
          .servicecollection{
            width: 33.33%;
            .serviceName{
              height: 30px;
              font-weight: 400;
              font-size: 22px;
              line-height: 25px;
              @include btn-animate(#5f6163);
            }
          }
        }
      }
    }
    .rightssection{
      margin-top: 45px;
      width: 100%;
      .rightsbox{
        margin-top: 10px;
        height: 90px;
        border-top: 0.5px solid #E5E5E5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 700;
        font-size: 23px;
        line-height: 25px;
      }
    }
    .aboutMock {
      flex: 1 1;
      margin-top: 30px;
      margin-bottom: 30px;
      // height: 100%;
      padding-left: 80px;
      display: flex;
      align-items: center;
      .cardhover:hover{
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
      }
      .mockBg {
        height: 70%;
        width: auto;
      }
      .textAbout {
        margin-left: 15%;
        flex: 1;
        .titleAbout {
          font-size: 70px;
          font-weight: 700;
          width: fit-content;
          position: relative;
        }
        .aboutDesc {
          width: 75%;
          font-size: 24px;
          line-height: 2;
        }
      }
    }
  }
  }

  @media screen and (max-width: 674px) {
    .homePage{
      .pageWrap{
        .heroSection{
          display: none;
        }
        .aboutMock{
          display: none;
        }
        .stickyFooter{
          display: none;
        }
        .allbelowsticky{
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 675px) {
    .homePage{
      .pageWrap{
        .mobileheroSection{
          display: none;
        }
        .totalscrollBody{
          display: none;
        }
      }
    }
  }
  
 