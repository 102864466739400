@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.oTCFundPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    .pageContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      .subNavBar {
        flex: 0 0 70px;
        border-bottom: 1px solid $border-color;
        display: flex;
        padding: 0 80px;
        .navItem {
          flex: 0 0 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 3px solid transparent;
          border-top: 3px solid transparent;
          font-size: 20px;
          transition: all 400ms ease;
          &.true {
            font-weight: bold;
            border-bottom-color: $primary-color;
          }
        }
        .search {
          width: 400px;
          margin-left: auto;
          border: none;
          background: none;
          border-left: 1px solid $border-color;
          padding: 0 30px;
          font-size: 20px;
        }
      }
      .paymentAppTabs {
        flex: 0 0 60px;
        border-bottom: 1px solid $border-color;
        padding: 0 80px;
        display: flex;
        .tabPayment {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 3px solid transparent;
          border-top: 3px solid transparent;
          font-size: 20px;
          transition: all 400ms ease;
          padding: 0 20px;
          font-weight: 600;
          img {
            height: 20px;
            width: auto;
            margin-right: 8px;
          }
          &.true {
            font-weight: bold;
            border-bottom-color: $primary-color;
          }
        }
      }
      .mainContent {
        height: 0;
        flex: 1;
        display: flex;
        .sendFundArea {
          flex: 1;
          padding: 80px 80px;
          display: flex;
          flex-direction: column;
          .label {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          .input {
            width: 50%;
            height: 80px;
            border-radius: 40px;
            background: none;
            border: 1px solid $border-color;
            padding: 0 40px;
            font-size: 22px;
            margin-bottom: 40px;
          }
          .btnStart {
            width: 50%;
            height: 68px;
            border-radius: 34px;
            background: $app-color;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: white;
            @include btn-animate(white);
          }
        }
        .sidebar {
          border-left: 1px solid $border-color;
          flex: 0 0 400px;
        }
      }
    }
  }
}
