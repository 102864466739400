@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.footerMenu {
  padding: 40px;
  display: flex;
  border-top: 1px solid $border-color;
  height: fit-content;
  height: 600px;
  .subMenu {
    min-height: 400px;
    flex: 0 0 25%;
    border-right: 1px solid $border-color;
    padding: 40px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    &:last-child {
      border-right: none;
      flex: 0 0 50%;
    }
    .menuItem {
      font-size: 28px;
      font-weight: 600;
      color: $primary-color;
    }
    .submenuList {
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .submenuItem {
        flex: 0 0 16.66%;
        height: 75px;
        min-height: 75px;
        align-items: center;
        display: flex;
        @include btn-animate;
        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
          margin-right: 6px;
        }
        span {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    .submenuListCol {
      margin-top: 20px;
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .submenuItem {
        flex: 0 0 14.28%;
        align-items: center;
        display: flex;
        @include btn-animate;
        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
          margin-right: 6px;
        }
        span {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
.footerCopyright {
  border-top: 1px solid $border-color;
  flex: 0 0 75px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
