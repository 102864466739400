@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.inpWrap {
  width: 100%;
  height: 80px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  padding: 0 20px;
  font-size: 20px;
  margin: 40px 0;
  .input {
    flex: 1;
    border: none;
    background: none;
    font-size: inherit;
  }
  .iconPaste {
    height: 45%;
    width: auto;
  }
}
