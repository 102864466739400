.sidemenu {
    width: 660px;
    height: 750px;
    position: fixed;
    background: white;
    margin-top: 808px;
    padding-top: 25px;
    z-index: 11;
    text-align: start;
    border: 1px solid #EDEDED;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 70px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
}

.pagemask {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden !important;
}

.close-img {
    margin-top: -138px;
    margin-left: -77px;
}

.side-text {
    margin-left: 100px;
}

.side-text1 {
    margin-left: 120px;
}

.card-title {
    font-weight: 800;
    font-size: 25px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.card-desc {
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.card-data {
    margin-top: 10px;
    display: flex;
    transition: -webkit-transform .5s cubic-bezier(.4,0,.2,1);
    transition: transform .5s cubic-bezier(.4,0,.2,1);
    transition: transform .5s cubic-bezier(.4,0,.2,1),
    -webkit-transform .5s cubic-bezier(.4,0,.2,1);}

.card-data:hover{
    transform: scale(1.1);
    transition: 0.4s all ease;
    // transition: -webkit-transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1),-webkit-transform .5s cubic-bezier(.4,0,.2,1);}
}

.company-logo {
    border: 0.5px solid #E5E5E5;
    border-radius: 5px;
    padding: 13px;
    width: 65px;
    height: 65px;
}

.card-text {
    margin-left: 10px;
}

.comp-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: 10px;

}

.comp-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -16px;
}

.rightmenu {
    width: 550px;
    height: 520px;
    position: fixed;
    background: white;
    padding-top: 25px;
    z-index: 11;
    text-align: start;
    border: 1px solid #EDEDED;
    margin-top: 580px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 70px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    right: 60px;
}

// @media only screen and (min-width: 350px) and (max-width: 500px) {
//     .rightmenu {
//         width: 290px;
//     }
//   }

// @media only screen and (min-width: 500px) and (max-width: 620px) {
// .rightmenu {
//     width: 350px;
// }
// }

.close-img-right {
    margin-top: -138px;
    margin-left: 503px;
}

.right-card-title {
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -22px;

}

.right-card-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -13px;
}