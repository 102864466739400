@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.preregisterBox {
  background-color: white;
  height: 60%;
  width: 100%;
  max-width: 550px;
  margin: auto;
  border-radius: 30px;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 80px;
  position: relative;
  .logo {
    width: 100%;
  }
  .inputWrap,
  .button {
    height: 70px;
    border-radius: 15px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .inputWrap {
    border: 1px solid $border-color;
    display: flex;
    position: relative;
    .input {
      background: none;
      border: none;
      height: 100%;
      width: 100%;
    }
    .circle {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 18px;
      width: 18px;
      border-radius: 9px;
      background: #ff0000;
      &.true {
        background: $app-color;
      }
    }
  }
  .slots {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: $app-color;
    margin-bottom: -20px;
  }
  .button {
    background: $primary-color;
    color: white;
    border: none;
    &:disabled {
      opacity: 0.5;
    }
  }
  .success {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 2;
    margin-top: 10%;
  }
  &.true {
    justify-content: center;
  }
  @include mdportrait {
    height: 100%;
    width: 100%;
    border-radius: 0;
    padding: 40px;
    justify-content: center;
    .inputWrap {
      margin: 25% 0;
    }
  }
}
